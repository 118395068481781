/* global document, navigator, window */

const BREAKPOINT_LG = 1056;
const useragent = navigator.userAgent;
const isiOSPlatform = (useragent.match(/(iPad|iPhone)/g));
const link = document.querySelector('.js-fb-link');

function changeLinkHref() {
	if (link) {
		link.addEventListener('click', () => {
			if (window.innerWidth < BREAKPOINT_LG) {
				setTimeout(() => {
					window.location = link.href;
				}, 25);
				if (isiOSPlatform) {
					window.location = link.dataset.schemeIos;
				} else {
					window.location = link.dataset.scheme;
				}
			}
		});
	}
}

document.addEventListener('resize', () => {
	changeLinkHref();
});

document.addEventListener('DOMContentLoaded', () => {
	changeLinkHref();
});
