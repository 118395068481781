/* global document */
import $ from 'jquery';
import naja from 'naja';

function initInputs() {
	$.ajax({
		url: $('.js-petition-signatures').data('link'),
		method: 'GET',
	}).done((data) => {
		$('.js-petition-signatures-value').text(data);
	});
}

document.addEventListener('DOMContentLoaded', () => {
	initInputs();
});

naja.snippetHandler.addEventListener('afterUpdate', () => {
	initInputs();
});
