/* global document, window */

import { BREAKPOINT_LG, isElementInViewport, scrollByInList } from './utils';

const list = document.querySelector('.js-convict-list');
const avatars = document.querySelectorAll('.js-convict-avatar');

const activeClassName = 'is-active';
const visibleClassName = 'is-visible';

function toggleCoverImage(newConvictName) {
	const currentVisibleImage = document.querySelector(`.js-cover-image.${visibleClassName}`);
	const newVisibleImage = document.querySelector(`.js-cover-image[data-convict-name="${newConvictName}"]`);

	if (currentVisibleImage && newVisibleImage) {
		currentVisibleImage.classList.remove(visibleClassName);
		newVisibleImage.classList.add(visibleClassName);
	}
}

function toggleConvict(item) {
	const elem = item;
	const newConvictName = elem.dataset.convictName;
	const currentActiveConvict = document.querySelector(`.js-convict-avatar.${activeClassName}`);
	const currentConvictName = currentActiveConvict.dataset.convictName;

	if (currentConvictName !== newConvictName) {
		toggleCoverImage(newConvictName);

		if (currentActiveConvict) {
			currentActiveConvict.classList.remove(activeClassName);
			elem.classList.add(activeClassName);
		}
	}
}

function init() {
	if (list) {
		const items = document.querySelectorAll('.js-convict');

		list.addEventListener('scroll', () => {
			items.forEach((item) => {
				if (isElementInViewport(item) && window.innerWidth < BREAKPOINT_LG) {
					const selectedConvict = item.dataset.convictName;
					const avatarItem = document.querySelector(`.js-convict-avatar[data-convict-name="${selectedConvict}"]`);

					toggleConvict(avatarItem);
				}
			});
		});
	}
}

avatars.forEach((avatar) => {
	avatar.addEventListener('click', (event) => {
		const convict = document.querySelector(`.js-convict[data-convict-name="${avatar.dataset.convictName}"]`);

		if (convict) {
			if (window.innerWidth >= BREAKPOINT_LG) {
				toggleConvict(event.currentTarget);
			} else {
				scrollByInList(convict.offsetLeft, list);
			}
		}
	});

	avatar.addEventListener('mouseenter', (event) => {
		toggleCoverImage(event.currentTarget.dataset.convictName);
	});

	avatar.addEventListener('mouseleave', () => {
		const currentActiveConvict = document.querySelector(`.js-convict-avatar.${activeClassName}`);

		toggleCoverImage(currentActiveConvict.dataset.convictName);
	});
});

document.addEventListener('DOMContentLoaded', () => {
	init();
});
