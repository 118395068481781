/* global document, window */
import naja from 'naja';

import { BREAKPOINT_MD, isElementInViewport, scrollByInList } from './utils';

const THUMB_RADIUS = 8;
const THUMB_RADIUS_MD = 16;

function scrollByDirection(direction, list) {
	const offset = direction === 'left' ? list.scrollLeft - list.clientWidth : list.scrollLeft + list.clientWidth;

	scrollByInList(offset, list);
}

function slideThumbInTimeline(currentSliderItem) {
	const thumb = document.querySelector('.js-carousel-thumb');
	const thumbRadius = window.innerWidth >= BREAKPOINT_MD ? THUMB_RADIUS_MD : THUMB_RADIUS;

	const currentSliderItemWidth = currentSliderItem.getBoundingClientRect().width;
	const currentSliderItemLeftOffset = currentSliderItem.getBoundingClientRect().left;
	const currentSliderItemCenter = currentSliderItemLeftOffset + (currentSliderItemWidth / 2);

	thumb.style.left = `${currentSliderItemCenter
	- currentSliderItem.parentElement.getBoundingClientRect().left
	- thumbRadius}px`;

	const wrap = document.querySelector('.js-carousel-timeline-wrap');

	if (wrap) {
		const wrapScrollLeft = currentSliderItem.offsetLeft + currentSliderItem.offsetWidth / 2 - wrap.offsetWidth / 2;
		scrollByInList(wrapScrollLeft, wrap);
	}
}

function slideTo(currentItem) {
	const selectedTime = currentItem.dataset.time;
	const carouselItem = document.querySelector(`.js-carousel-item[data-time="${selectedTime}"]`);
	const list = document.querySelector('.js-carousel-list');

	slideThumbInTimeline(currentItem, document);
	scrollByInList(carouselItem.offsetLeft, list);
}

function setTimelineInputChecked(time) {
	const timelineInput = document.querySelector(`.js-carousel-timeline-input[value="${time}"]`);

	if (timelineInput) {
		timelineInput.checked = true;
	}
}

function listInit() {
	const list = document.querySelector('.js-carousel-list');

	if (list) {
		const items = document.querySelectorAll('.js-carousel-item');

		list.addEventListener('scroll', () => {
			items.forEach((item) => {
				if (isElementInViewport(item) && window.innerWidth < BREAKPOINT_MD) {
					const selectedTime = item.dataset.time;
					const timelineItem = document.querySelector(`.js-carousel-timeline-item[data-time="${selectedTime}"]`);

					if (timelineItem) {
						setTimelineInputChecked(selectedTime);
						slideThumbInTimeline(timelineItem);
					}
				}
			});
		});
	}
}

function arrowsInit() {
	const arrows = document.querySelectorAll('.js-carousel-arrow');
	const list = document.querySelector('.js-carousel-list');

	if (list) {
		arrows.forEach((arrow) => {
			arrow.addEventListener('click', () => {
				scrollByDirection(arrow.dataset.direction, list);
			});
		});
	}
}

function timelineInit() {
	const selectedInput = document.querySelector('.js-carousel-timeline-input:checked');

	if (selectedInput) {
		const selectedItem = document.querySelector(`.js-carousel-timeline-item[data-time="${selectedInput.value}"]`);

		if (selectedItem) {
			slideTo(selectedItem);
		}
	}
}

function timelineItemInit() {
	const items = document.querySelectorAll('.js-carousel-timeline-item');

	items.forEach((item) => {
		item.addEventListener('click', () => {
			slideTo(item);
		});
	});
}

function init() {
	listInit();
	arrowsInit();
	timelineInit();
	timelineItemInit();
}

document.addEventListener('DOMContentLoaded', () => {
	init();
});

window.addEventListener('resize', () => {
	init();
});

naja.snippetHandler.addEventListener('afterUpdate', () => {
	init();
});
