import $ from 'jquery';
import naja from 'naja';
import netteForms from 'nette-forms';

import './api';
import './carousel';
import './convicts';
import './countdown';
import './fb-link';
import './forms';
import './parallax';
import './petitions-checkbox';
import './textfields';

$(() => {
	naja.initialize();
	naja.formsHandler.netteForms = netteForms;
});
