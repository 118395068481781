/* global document */

import naja from 'naja';

// Set active state to input if it is not empty
function updateState(input) {
	if (input.value.length > 0) {
		input.classList.add('is-active');
	} else {
		input.classList.remove('is-active');
	}
}

function initInputs() {
	const inputs = document.querySelectorAll('.js-input');

	inputs.forEach((input) => {
		updateState(input);

		input.addEventListener('change', () => {
			updateState(input);
		});

		input.addEventListener('input', () => {
			updateState(input);
		});
	});
}

document.addEventListener('DOMContentLoaded', () => {
	initInputs();
});

naja.snippetHandler.addEventListener('afterUpdate', () => {
	initInputs();
});
