/* global document, window */

const BREAKPOINT_MD = 768;
const BREAKPOINT_LG = 1056;

function isElementInViewport(element) {
	if (!element) {
		return false;
	}

	const clientRect = element.getBoundingClientRect();
	return clientRect.top >= 0
		&& clientRect.left >= 0
		&& clientRect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

function scrollByInList(leftOffset, list) {
	if (list) {
		list.scrollTo({
			top: 0,
			left: leftOffset,
			behavior: 'smooth',
		});
	}
}

export {
	BREAKPOINT_MD,
	BREAKPOINT_LG,
	isElementInViewport,
	scrollByInList,
};
