/* global document, window */

const DURATION_IN_MS = 180;
let hasBeenCountdownStopped = false;

const countdownElements = document.querySelectorAll('.js-countdown');
const stickyCountdown = document.querySelector('.js-sticky-countdown');
const countdownInfo = document.querySelector('.js-countdown-info');
const btnCloseCountdown = document.querySelector('.js-countdown-btn-close');
const headerLogo = document.querySelector('.js-header-logo');
const petitionForm = document.querySelector('.js-countdown-reset-form');

const fixedClassName = 'is-fixed';
const hiddenClassName = 'is-hidden';
const raisedClassName = 'is-raised';

let countdown = DURATION_IN_MS;

function formatTime(currentCountdown) {
	const currentMinutes = parseInt((currentCountdown / 60).toString(), 10);
	const formattedMinutes = currentMinutes < 10 ? `${currentMinutes}` : currentMinutes;
	const currentSeconds = parseInt((currentCountdown % 60).toString(), 10);
	const formattedSeconds = currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds;

	return `${formattedMinutes}:${formattedSeconds}`;
}

function startCountdown() {
	setInterval(() => {
		if (!hasBeenCountdownStopped) {
			countdownElements.forEach((countdownElement) => {
				const element = countdownElement;

				element.innerHTML = formatTime(countdown);
			});

			countdown -= 1;

			if (countdown < 0) {
				hasBeenCountdownStopped = true;
				stickyCountdown.classList.add(fixedClassName);
				countdownInfo.classList.remove(hiddenClassName);
				headerLogo.classList.add(raisedClassName);
				document.body.style.overflowY = 'hidden';
			}
		}
	}, 1000);
}

function setCountdownSticky() {
	if (stickyCountdown && !hasBeenCountdownStopped) {
		const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

		if (scrollPosition > 0) {
			stickyCountdown.classList.add(fixedClassName);
		} else {
			stickyCountdown.classList.remove(fixedClassName);
		}
	}
}

function closeCountdown() {
	stickyCountdown.classList.remove(fixedClassName);

	if (countdownInfo) {
		countdownInfo.classList.add(hiddenClassName);
		headerLogo.classList.remove(raisedClassName);
		document.body.style.overflowY = 'auto';
	}
}

function resetCountdown() {
	countdown = DURATION_IN_MS;

	countdownElements.forEach((countdownElement) => {
		const element = countdownElement;

		element.innerHTML = formatTime(countdown);
	});

	hasBeenCountdownStopped = true;
	closeCountdown();
}

document.addEventListener('DOMContentLoaded', () => {
	startCountdown();
});

window.addEventListener('resize', () => {
	setCountdownSticky();
});

window.addEventListener('scroll', () => {
	setCountdownSticky();
});

if (btnCloseCountdown) {
	btnCloseCountdown.addEventListener('click', () => {
		stickyCountdown.style = 'top: auto; bottom: 0; transform: translateY(100%)';
		window.location.hash = '#podepsat-petici';
		closeCountdown();
	});
}

if (petitionForm) {
	petitionForm.addEventListener('submit', () => {
		resetCountdown();
	});
}
