/* global document */
import $ from 'jquery';
import naja from 'naja';
import netteForms from 'nette-forms';

function getErrorElement(element) {
	const $element = $(element);
	return [element, $element];
}

function getErrorTarget($element) {
	const $target = $element.closest('.form-group');

	if ($target.length) {
		return [$target.get(0), $target];
	}

	// Parent's selector changed because of HTML structure of checkbox.
	// In other cases just use parent().

	let $parent;
	if ($element.hasClass('js-petitions-checkbox')) {
		$parent = $element.closest('div[class=petitions-wrap]');
	} else {
		$parent = $element.closest('div[class*=wrap]');
	}

	return [$parent.get(0), $parent];
}

netteForms.showFormErrors = function showFormErrors(form, errors) {
	const displayed = [];
	let focusElem;

	$(form).find('.has-error').removeClass('has-error');
	$(form).find('.js-form-error').remove();

	for (let i = 0; i < errors.length; i += 1) {
		const { message } = errors[i];
		const [element, $element] = getErrorElement(errors[i].element);
		const [target, $target] = getErrorTarget($element);

		if (!displayed.some((error) => error.target === target && error.message === message)) {
			$target.addClass('has-error');
			$target.append($('<span></span>').addClass('error js-form-error').text(message));
			displayed.push({ target, message });
		}

		$element.addClass('has-error');
		if ($element.attr('type') === 'radio' || $element.attr('type') === 'checkbox') {
			($target.is('.form-group') ? $target.children('.form-check,.form-check-nps') : $target).addClass('has-error');
		}

		if (!focusElem && element.focus) {
			focusElem = element;
		}
	}

	if (focusElem) {
		focusElem.focus();
	}
};

netteForms.initOnLoad();

$(document).on('change', '.has-error', function invalidChanged(e) {
	const $this = $(this);

	if ($this.is('.form-group')) {
		let valid = true;
		const elements = e.target.form ? Array.prototype.filter.call(e.target.form.elements, (element) => (
			$(element).closest('.form-group').is($this)
		)) : [e.target];
		for (let i = 0; i < elements.length; i += 1) {
			if (!netteForms.validateControl(elements[i], null, true)) {
				valid = false;
				break;
			}
		}

		if (valid) {
			$this.removeClass('has-error');
			$this.find('.has-error').removeClass('has-error');
			$this.find('.js-form-error').remove();
		}
	} else if (!$this.closest('.form-group').length) {
		if (netteForms.validateControl(e.target, null, true)) {
			$this.removeClass('has-error');
			$this.find('.js-form-error').remove();
		}
	}
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const petition = event.detail.snippet.querySelector('.js-petition');

	if (petition) {
		petition.scrollIntoView();
	}
});
